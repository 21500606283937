<template>
	<v-row justify="center">
		<v-dialog :value="dialog" max-width="700" @input="closeDialog">
			<v-card class="dialog-card">
				<v-card-title class="headline accent--text pb-4">
          Добавление продолжительности большого перерыва
        </v-card-title>

				<v-card-text outlined tile class="border-top">
					<v-container>
						<v-row>
							<v-col cols="12" class="pb-0 pt-5 px-0">
                <v-text-field
                  v-model="bigBreakDuration"
                  label="Продолжительность большого перерыва"
                  placeholder="От 10 до 60"
                  v-mask="'##'"
                  :error-messages="errors"
                  @input="$v.bigBreakDuration.$touch()"
                  @blur="$v.bigBreakDuration.$touch()"
                />
              </v-col>
            </v-row>
					</v-container>
				</v-card-text>				

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="closeDialog">Отмена</v-btn>

          <v-btn
            :disabled="$v.$invalid"
						:loading="loading"
            color="success"
            text
            @click="save"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { regexFrom10To60, checkValueInArray } from '@/scripts/validation'

export default {
  name: 'BigBreakDurationDialog',

	props: {
		dialog: Boolean,
		loading: Boolean,
    values: Array
	},

	data: () => ({
    bigBreakDuration: null
	}),

  validations() {
    return {
      bigBreakDuration: {
        required,
        regexFrom10To60,
        checkValueInArray: () => checkValueInArray(this.values, +this.bigBreakDuration)
      }
    }
  },

	computed: {
    errors() {
      const errors = []
      if (!this.$v.bigBreakDuration.$dirty) return errors
      !this.$v.bigBreakDuration.required && errors.push('Обязательное поле')
      !this.$v.bigBreakDuration.regexFrom10To60 && errors.push('Значение от 10 до 60')
      !this.$v.bigBreakDuration.checkValueInArray && errors.push('Данное значение уже существует')
      return errors
    },
	},

	methods: {
    save() {
      this.$emit('save', this.bigBreakDuration)
    },

    resetModal() {
      this.$v.$reset()
      this.bigBreakDuration = null
    },

    closeDialog() {
      this.resetModal()
			this.$emit('closeDialog')
		}
	},

  watch: {
    dialog(val) {
      !val && this.resetModal()
    }
  }
}
</script>