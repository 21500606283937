<template>
  <div v-show="dataLoaded">
    <v-row class="my-0">
      <v-col cols="12" class="py-0">
        <v-card class="table-card">
          <v-data-table
            ref="timeManagement"
            class="base-table"
            fixed-header
            hide-default-footer
            :headers="headers"
            :items="breakDurations"
            :items-per-page="-1"
          >
            <template #header.minutes="{ header }">
              <span v-html="header.text"></span>
            </template>

            <template #header.actions="{ header }">
              <v-icon
                color="btnIcon"
                class="add-btn"
                size="22"
                title="Добавить значение"
                @click="dialog = true"
              >
                mdi-plus
              </v-icon>
            </template>

            <template #item.actions="{ item }">
              <v-icon
                color="btnIcon"
                class="remove-btn"
                size="22"
                title="Удалить значение"
                @click="confirmRemove(item)"
              >
                mdi-trash-can-outline
              </v-icon>
            </template>

            <template slot="no-data">
              <div>Нет данных</div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <BreakDurationDialog
      :dialog="dialog"
      :loading="loading"
      :values="valuesArray"
      @save="save"
      @closeDialog="closeDialog"
    />

    <DeleteConfirmDialog
      :dialog="confirmDialog"
      :loading="deleteLoading"
      :message="'Вы уверены, что хотите удалить значение?'"
      @confirm="remove"
      @closeConfirmModal="closeConfirmDialog"
    />
  </div>
</template>

<script>
import { scheduleApi } from '@/api'
import { mapGetters } from 'vuex'
import BreakDurationDialog from '@/components/department/timeManagement/dialogs/BreakDuration'
import DeleteConfirmDialog from '@/components/dialogs/DeleteConfirm'

export default {
  name: 'BreakDurationTable',

  components: {
    BreakDurationDialog,
    DeleteConfirmDialog
  },

  async created() {
    await this.getBreakDurations()
    this.dataLoaded = true
    this.$emit('dataLoaded')
  },

  mounted() {
    this.setTableHeight()
    window.addEventListener("resize", this.setTableHeight)
  },

  destroyed() {
    window.removeEventListener("resize", this.setTableHeight)
  },

  data: () => ({
    dataLoaded: false,
    headers: [
      { text: 'Продолжительность<br>перерыва', value: 'minutes', sortable: false, align: 'left' },
      { text: '', value: 'actions', sortable: false, align: 'center', width: '40px' }
    ],
    selectedItem: null,
    dialog: false,
    confirmDialog: false,
    loading: false,
    deleteLoading: false,

    breakDurations: []
  }),

  computed: {
    ...mapGetters('system', ['techMessageHeight']),
    ...mapGetters('department', ['departmentId']),

    valuesArray() {
      return this.breakDurations.map(el => el.minutes)
    }
  },

  methods: {
    async getBreakDurations() {
      try {
        this.breakDurations = await scheduleApi.getBreaksDurationList(this.departmentId)
      } catch (e) {
        console.log(e.status)
      }
    },

    async save(val) {
      try {
        this.loading = true

        const body = {
          departmentId: this.departmentId,
          type: "break",
          minutes: val
        }

        const data = await scheduleApi.sendDuration(body)
        this.breakDurations.push(data)
        this.breakDurations.sort((a, b) => a.minutes - b.minutes)
        this.closeDialog()
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка сохранения продолжительности перерыва')
      } finally {
        this.loading = false
      }
    },

    async remove() {
      try {
        if (this.selectedItem.id) {
          this.deleteLoading = true

          await scheduleApi.removeDuration(this.selectedItem.id)
          const index = this.breakDurations.findIndex(el => el.id === this.selectedItem.id)
          index !== -1 && this.breakDurations.splice(index, 1)
          this.closeConfirmDialog()
        }
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка удаления продолжительности перерыва')
      } finally {
        this.deleteLoading = false
      }
    },

    setTableHeight() {
      const otherElements = 261 + this.techMessageHeight

      if (this.$refs?.timeManagement) {
        this.$refs.timeManagement.$el.style.maxHeight = window.innerHeight - otherElements + 'px'
      }
    },

    confirmRemove(item) {
      this.selectedItem = item
      this.confirmDialog = true
    },

    closeDialog() {
      this.dialog = false
      this.selectedItem = null
    },

    closeConfirmDialog() {
      this.confirmDialog = false
      this.selectedItem = null
    }
  }
}
</script>