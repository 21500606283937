<template>
  <div>
    <div class="text-center" v-if="!departmentDataLoaded || !checkTableDataLoaded">
      <v-progress-circular class="mt-6" :size="50" color="primary" indeterminate />
    </div>

    <div class="mx-4" v-if="departmentDataLoaded">
      <div v-show="checkTableDataLoaded">
        <PageTitle title="Время проведения занятий" />

        <v-row class="my-0">
          <v-col cols="12" class="pt-0 pb-5">
            <h4 class="department">{{ departmentName }}</h4>
          </v-col>
        </v-row>

        <v-card elevation="0" class="tables-card">
          <LessonBeginTimeTable
            class="mini-table"
            @dataLoaded="tableDataLoaded.push(true)"
          />

          <BreakDurationTable
            class="mini-table"
            @dataLoaded="tableDataLoaded.push(true)"
          />

          <BigBreakBeginTimeTable
            class="mini-table"
            @dataLoaded="tableDataLoaded.push(true)"
          />

          <BigBreakDurationTable
            class="mini-table"
            @dataLoaded="tableDataLoaded.push(true)"
          />
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageTitle from '@/components/ui/Title'
import LessonBeginTimeTable from '@/components/department/timeManagement/tables/LessonBeginTime'
import BreakDurationTable from '@/components/department/timeManagement/tables/BreakDuration'
import BigBreakBeginTimeTable from '@/components/department/timeManagement/tables/BigBreakBeginTime'
import BigBreakDurationTable from '@/components/department/timeManagement/tables/BigBreakDuration'

export default {
  name: 'TimeManagement',

  metaInfo: {
    title: 'Время проведения занятий'
  },

  components: {
    PageTitle,
    LessonBeginTimeTable,
    BreakDurationTable,
    BigBreakBeginTimeTable,
    BigBreakDurationTable
  },

  async created() {
    await this.$store.dispatch('user/getCurrent')
    await this.$store.dispatch('department/checkDepartmentInLocalStorage')
    this.departmentDataLoaded = true
  },

  mounted() {
    this.hideHtmlOverflow()
  },

  destroyed() {
    this.showHtmlOverflow()
  },

  data: () => ({
    departmentDataLoaded: false,
    tableDataLoaded: []
  }),

  computed: {
    ...mapGetters('department', ['departmentName']),

    checkTableDataLoaded() {
      // hide preloader when all 4 tables data loaded
      return this.tableDataLoaded.length === 4
    }
  },

  methods: {
    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>

<style scoped>
.tables-card {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  background-color: var(--background-color) !important;
}

.mini-table {
  min-width: calc(100% / 4 - 10px);
  max-width: calc(100% / 4 - 10px);
}

.mini-table:last-child {
  margin-right: 0
}
</style>